<template>
    <div id="couponListPage">
      <div class="scrollView">
        <van-tabs
          :ellipsis="false"
          background="#232324"
          color="#36ABF5"
          title-inactive-color="rgba(255, 255, 255, 0.6)"
          title-active-color="#fff"
          v-model="tabActive"
        >
          <van-tab
            :title="item"
            v-for="(item, index) in tabs"
            :key="index"
          >
          <div class="container" >
            <Empty
              v-if="detail.totalCount === 0"
              emptyImg="icon-empty-2"
              tip="クーポン券がありません"
            ></Empty>
            <van-list
              v-else
              v-model="detail.loading"
              :finished="detail.finished"
              loading-text="ローディング..."
              @load="onLoad"
            >
              <ul class="list">
                <li
                  v-for="(item, index) in detail.list"
                  :key="index"
                  class="list-item "
                  :class="{active: item.active, 'style-1': tabActive === 0 }"
                >
                  <div class="content">
                    <div class="content-left">
                      <div class="left">
                        <div class="num">{{ item.price }}<span class="text">円</span></div>
                        <div class="tip" v-if="item.type===1">条件つき</div>
                        <div class="tip" v-if="item.type===2">全商品適用</div>
                      </div>
                      <div class="right">
                        <div class="title">{{ item.name }}</div>
                        <div class="date">有効期限：<br>
                          <p class="text">{{ item.validityStartTime | formatDate('yyyy/MM/dd hh:mm') }}</p>
                          <p class="text">{{ item.validityEndTime | formatDate('yyyy/MM/dd hh:mm') }}</p>
                        </div>
                        <!-- <div class="date" v-if="item.validityType*1 === 2">有効期限：<p class="text">{{ item.validityEndTime | formatDate('yyyy/MM/dd hh:mm') }}</p></div> -->
                        <!-- <div class="date" v-else-if="item.validityType*1 === 1">受領後{{ item.day }}日間有効</div> -->
                        <!-- <div class="date">受領後{{ item.day }}日間有効</div> -->
                        <!-- <div class="date">有効期限：<p class="text">{{ item.validityEndTime }}</p></div> -->
                        <div class="explain" @click.prevent="onExplain(item,index)">
                          <span content="name text-ellipsis">利用説明</span>
                          <van-icon name="arrow" />
                        </div>
                      </div>
                    </div>
                    <div class="content-right" @click="jumpTo(index)">
                      <span class="name">{{tabs[tabActive]}}</span>
                    </div>
                  </div>
                  <transition-group name="slider">
                    <div v-if="item.active" class="bottom" key="1">
                      条件：
                      <template v-if="item.type ===1">
                        金額は{{ item.full }}円以上の場合{{ item.price }}円値引き
                      </template>
                      <template v-else-if="item.type ===2">
                        全商品適用
                      </template>
                    </div>
                  </transition-group>
                </li>
              </ul>
            </van-list>
          </div>
          </van-tab>
        </van-tabs>
      </div>

      <div class="bottom"  @click="change">
        クーポンの期限切れまで3日前にメールでお知らせします
        <i class="icon icon-checked" v-if="isCheck"></i>
        <i class="icon icon-nchecked" v-else></i>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Tab, Tabs } from 'vant';
import { Empty } from '@/components';
export default {
  name: 'CouponList',
  components: {
    'van-tab': Tab,
    'van-tabs': Tabs,
    Empty
  },
  data () {
    return {
      isCheck: true,
      tabActive: 0,
      tabs: ['未使用', '使用済', '期限切れ'],
      list: [{ active: false }, { active: false }, { active: false }, { active: false }],
      detail: {
        list: [],
        pageNo: 1,
        pageSize: 10,
        totalCount: '',
        finished: false,
        loading: false
      },
      triggered: false,
      refreshTimer: 0,
      active: {}
    };
  },
  watch: {
    tabActive () {
      this.init();
    }
  },
  computed: {
    ...mapGetters(['personInfo'])
  },
  mounted () {
    this.isCheck = this.personInfo.couponEmailNotify === 1;
  },
  methods: {

    /* 初始化数据 */
    init () {
      this.detail = {
        list: [],
        pageNo: 1,
        pageSize: 10,
        totalCount: '',
        finished: false,
        loading: true
      };
      this.onLoad();
    },

    onLoad () {
      const { detail, tabActive } = this;
      const { pageNo, pageSize } = detail;
      this.$http.get('mine/discountList', {
        params: {
          state: tabActive + 1,
          pageNo,
          pageSize
        }
      }).then((res) => {
        let { nextPage, result, totalCount, isHasNext } = res.data || {};
        detail.finished = !isHasNext;
        detail.list.push(...result.map((item) => {
          item.active = false;
          return item;
        }));
        detail.pageNo = nextPage;
        detail.totalCount = totalCount;
        detail.loading = false;
        this.detail = detail;
      });
    },

    /* 点击去使用跳转至视频页 */
    jumpTo (index) {
      if (this.tabActive === 0) {
        this.$router.push({
          name: 'Category'
        });
      }
    },

    /* 优惠券条件展示 */
    onExplain (item, index) {
      item.active = !item.active;
    },

    /* 切换短信通知 */
    change () {
      this.isCheck = !this.isCheck;
      let state = this.isCheck ? 1 : 0;
      this.$http.get('mine/emailNotify', {
        params: { state }
      }).then((res) => {});
    }
  }
};
</script>

<style scoped lang="less">
  #couponListPage {
    height: 100%;
    display: flex;
    flex-direction: column;

    .scrollView{
      flex: 1;
      overflow-y: scroll;
      /deep/
      .van-tabs {
        margin-bottom: 10px;

        .van-tabs__wrap {
          height: auto;

          .van-tabs__nav--line {
            padding: 14px 0 20px;

            .van-tab {
              font-size: 30px;
              line-height: 40px;
            }

            .van-tabs__line {
              bottom: 4px;
              width: 40px;
              height: 8px;
              border-radius: 4px;
            }
          }
        }
      }

      .container {
        position: relative;
        height: 100vw;

        .list {
          padding: 30px 30px 0;

          .list-item {
            &:not(:last-child) {
              margin-bottom: 42px;
            }

            .content {
              position: relative;
              z-index: 1;
              color: rgba(255, 255, 255, 0.6);
              background-color: #353535;
              border-radius: 16px;

              &,
              .content-left,
              .content-right {
                display: flex;
                align-items: center;
              }

              .content-left {
                flex: 1;

                .left {
                  flex-shrink: 0;
                  width: 200px;
                  padding: 20px;
                  text-align: center;

                  .num {
                    font-size: 48px;
                    line-height: 104px;
                    white-space: nowrap;
                    display: flex;
                    justify-content: center;
                    .text{
                      font-size: 44px;
                    }
                  }

                  .tip {
                    font-size: 26px;
                    line-height: 42px;
                    .text-overflow();
                  }
                }

                .right {
                  font-size: 24px;
                  line-height: 32px;
                  color: rgba(255, 255, 255, 0.6);

                  & > :not(:last-child) {
                    margin-bottom: 6px;
                  }

                  .title{
                    font-weight: bold;
                  }
                  .title,.date{
                    padding-right: 10px;
                    .text{
                      font-size: 16px;
                    }
                  }

                  .explain {
                    display: flex;
                    align-items: center;

                    .van-icon {
                      flex-shrink: 0;
                      margin-left: 10px;
                      font-weight: bold;
                      transition: all 0.3s;
                    }
                  }
                }
              }

              .content-right {
                flex-shrink: 0;
                align-self: stretch;
                justify-content: center;
                width: 170px;
                font-size: 28px;
                border-left: 2px dashed rgba(255, 255, 255, 0.6);
              }

              &::before,
              &::after {
                position: absolute;
                right: 169px;
                z-index: 1;
                width: 44px;
                height: 22px;
                content: '';
                background-color: @primaryBg;
                transition: all 0.3s;
                transform: translateX(50%);
              }

              &::before {
                top: 0;
                border-radius: 0 0 22px 22px;
              }

              &::after {
                bottom: 0;
                border-radius: 22px 22px 0 0;
              }
            }

            .bottom {
              min-height: 146px;
              padding: 64px 26px 26px;
              margin-top: -22px;
              font-size: 26px;
              line-height: 38px;
              background-color: #2d2e2e;
              border-radius: 16px;
            }

            &.active {
              .content {
                .content-left {
                  .right {
                    .explain {
                      .van-icon {
                        transform: rotateZ(90deg);
                      }
                    }
                  }
                }

                &::after {
                  background-color: #2d2e2e;
                }
              }
            }

            &.style-1 {
              .content {
                color: #fff;
                background-image: linear-gradient(270deg, #ff6059 0%, #ff804a 100%);
              }
            }
          }
        }
      }
    }

    .bottom{
      font-size: 26px;
      color: #fff;
      display: flex;
      padding: 20px 0;
      text-align: center;
      margin: 0 auto;
      align-items: center;
      .icon-checked{
        width: 32px;
        height: 32px;
        background-color: #fff;
        border-radius: 50%;
        background-image: url("~@/assets/images/icon_checkbox_02.png");
      }
      .icon-nchecked{
        width: 32px;
        height: 32px;
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }
</style>